import React from "react";

const Footer = () => (
    <div className="footer-territeo col-auto">
        <footer>
            <div id="help-info" className="ng-scope">
            <div id="phone-icon"><i className="fa fa-phone" aria-hidden="true"></i></div>
            <div id="phone-info">
                <p>Vous ne trouvez pas de réponse à votre question,</p>
                <p>contactez le support TERRITEO</p>
                <p>09.72.56.82.02 - <a href="mailto:support@territeo.com">support@territeo.com</a></p>
            </div>
            </div>
        </footer>  
    </div>
)

export default Footer
